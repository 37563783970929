
import React from 'react';
import Navbar from '../Components/Navbar';
import HeroSection from '../Components/HeroSection';
import Footer from '../Components/Footer';
import {EnvelopeIcon } from '@heroicons/react/24/solid';

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <div className="min-h-screen bg-gray-50 flex flex-col items-center">
        {/* Company Introduction */}
        <section className="bg-gray-100 py-12 px-6 text-center w-full">
          <h1 className="text-4xl font-bold text-indigo-700">Birimo Insurance Agency</h1>
          <p className="mt-4 text-lg text-gray-700 max-w-3xl mx-auto">
            Birimo Insurance has grown into a trusted name in the insurance sector, known for its integrity, reliability, and commitment to its clients.
          </p>
        </section>

        {/* Contact Information Section */}
        <section className="bg-white py-10 px-6 w-full">
          <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* WhatsApp Contact */}
            <div className="p-6 shadow-lg rounded-lg hover:bg-indigo-50 transition">
              <h2 className="text-xl font-semibold text-indigo-700">WhatsApp</h2>
              <a
                href="https://wa.me/17632278620"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 text-gray-700 hover:text-indigo-600 transition"
              >
                <img src="/images/whatsapp.png" alt="WhatsApp Icon" className="w-5 h-5" />
                <span>+1 763-227-8620</span>
              </a>
            </div>

            {/* Email for Contact */}
            <div className="p-6 shadow-lg rounded-lg hover:bg-indigo-50 transition">
              <h2 className="text-xl font-semibold text-indigo-700">Email for Contact</h2>
              <a
                href="mailto:birimo.insurance@gmail.com"
                className="flex items-center space-x-2 text-gray-700 hover:text-indigo-600 transition"
              >
                <EnvelopeIcon className="w-5 h-5 text-indigo-700" />
                <span>birimo.insurance@gmail.com</span>
              </a>
            </div>

            {/* Email for Documents */}
            <div className="p-6 shadow-lg rounded-lg hover:bg-indigo-50 transition">
              <h2 className="text-xl font-semibold text-indigo-700">Email for Documents</h2>
              <a
                href="mailto:info@birimoinsurance.com"
                className="flex items-center space-x-2 text-gray-700 hover:text-indigo-600 transition"
              >
                <EnvelopeIcon className="w-5 h-5 text-indigo-700" />
                <span>info@birimoinsurance.com</span>
              </a>
            </div>
          </div>
        </section>

        {/* CEO Section */}
        <section className="bg-gray-100 py-12 px-6 w-full">
          <div className="max-w-4xl mx-auto flex flex-col md:flex-row items-center md:items-start">
            <img
              src="/images/abshir.jpg"
              alt="CEO Image"
              className="w-40 h-40 rounded-full shadow-lg mb-4 md:mb-0 md:mr-8"
            />
            <div>
              <h2 className="text-2xl font-bold text-indigo-700">Abshir Mohamed</h2>
              <p className="mt-2 text-gray-700">
                At the helm of <span className="text-indigo-600 font-semibold">Birimo Insurance</span> is our esteemed CEO, a visionary leader with a passion for delivering exceptional insurance services. With years of industry experience, he has been instrumental in shaping the company's strategic direction, fostering a culture of excellence, and driving innovation.
              </p>
              <div className="mt-4 space-y-2">
                <a
                  href="https://wa.me/16513152321"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-2 text-gray-700 hover:text-indigo-600 transition"
                >
                  <img src="/images/whatsapp.png" alt="WhatsApp Icon" className="w-5 h-5" />
                  <span>WhatsApp: 651-315-2321</span>
                </a>
                <a
                  href="mailto:abshir@birimoinsurance.com"
                  className="flex items-center space-x-2 text-gray-700 hover:text-indigo-600 transition"
                >
                  <EnvelopeIcon className="w-5 h-5 text-indigo-700" />
                  <span>Email: Abshir@birimoinsurance.com</span>
                </a>
              </div>
            </div>
          </div>
        </section>

     
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
