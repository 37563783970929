


import React from 'react';
import Slider from 'react-slick';

// Import logos
const companies = [
  { name: 'Progressive Insurance', logo: '/images/progressive.svg' },
  { name: 'National General Insurance', logo: '/images/national2.png' },
  { name: 'Bristol West', logo: '/images/bristol.svg' },
  { name: 'AMWINS Insurance Group', logo: '/images/amwins.svg' },
  { name: 'ePremium Insurance', logo: '/images/epremium.png' },
  { name: 'Foremost Insurance', logo: '/images/foremost.png' },
  { name: 'Dairy Land Insurance', logo: '/images/dairyland.avif' },
  { name: 'CNA Insurance', logo: '/images/cna.png' },
  { name: 'Coterie Insurance', logo: '/images/coterie.png' },
  { name: 'Hourly Insurance', logo: '/images/Hourly.svg' },
];

const PartnersSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of visible slides
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto px-4 py-16 mt-[6%] mb-[6%] w-[98%]" >
      {/* Section Header */}
      <div className="text-center mb-12">
        <h2 className="text-4xl font-extrabold text-gray-800 mb-4">Our Trusted Partners</h2>
        <p className="text-lg text-gray-600">
          We are proud to work with some of the best companies in the industry
        </p>
      </div>

      {/* Slider Section */}
      <Slider {...settings}>
        {companies.map((company) => (
          <div key={company.name} className="px-4">
            <div className="flex flex-col items-center">
              {/* Logo */}
              <div className="w-[150px] h-[80px] mb-4 flex justify-center items-center">
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  className="w-full h-full object-contain transition-transform transform hover:scale-105 duration-300 ease-in-out"
                />
              </div>
              {/* Company Name */}
              <p className="text-lg font-medium text-gray-700">{company.name}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersSlider;
