
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Use the provided coordinates for the location
const locationCoordinates = {
  lat: 44.94944543215779,
  lng: -93.281775651362,
};

// Custom marker icon
const customMarkerIcon = new L.Icon({
  iconUrl: "/images/placeholder.png", // Replace with your custom marker icon path if needed
  iconSize: [35, 50],
  iconAnchor: [17, 50],
  popupAnchor: [0, -45],
});

const OurLocation = () => {
  const [mapCenter, setMapCenter] = useState([locationCoordinates.lat, locationCoordinates.lng]);

  // Fly-to effect when the map loads
  const MapViewUpdater = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.flyTo(center, 17, { // Increased zoom level for a closer view
        animate: true,
        duration: 1.5,
      });
    }, [center, map]);
    return null;
  };

  return (
    <div className="container mx-auto px-4 py-8 mt-[8%]">
            <div className="mb-6">
        <a href="https://maps.app.goo.gl/W7qP1NitSHK6xWPr9?g_st=iw">
          <h2 className="text-3xl font-bold text-center text-gray-800">
            locations
          </h2>
        </a>
        <div className=" bg-white text-center text-2xl font-bold text-blue-600 pb-2 ">
          <p>
            <h4  className="text-red-600">Minneapolis: </h4>
            200 W lake St suit #324 ( new Karmell Mall near masjid )
          </p>
          
        
        </div>
      </div>

      {/* Map Container */}
      <div className="w-full h-[60vh] mt-6 rounded-lg shadow-xl overflow-hidden">
        <MapContainer
          center={mapCenter}
          zoom={16}  // Zoom level set to show a detailed view
          scrollWheelZoom={true}
          className="w-full h-full"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={mapCenter} icon={customMarkerIcon}>
            <Popup>
              <strong>Birimo Insurance Agency</strong>
              <br />
              2938 Pillsbury Ave, Suite 324
              <br />
              Minneapolis, MN 55408, United States
              <br />
              <a 
                href="https://maps.app.goo.gl/W7qP1NitSHK6xWPr9?g_st=iw" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-indigo-600 font-bold underline hover:text-indigo-800 transition"
              >
                Open in Google Maps
              </a>
            </Popup>
          </Marker>
          <MapViewUpdater center={mapCenter} />
        </MapContainer>
      </div>
    </div>
  );
};

export default OurLocation;
