import React, { useState } from 'react';

const options = [
    { name: 'Personal Auto', imagePath: '/images/CarIcon.png' },
   
    { name: 'Renters', imagePath: '/images/RenterIcon.png' },
    { name: 'Comercial Auto', imagePath: '/images/NewTruck.png' },
   
    { name: 'Business', imagePath: '/images/handIcon.png' },
    { name: 'Surety Bond', imagePath: '/images/agreement.png' },
    { name: 'Fidelity Bond', imagePath: '/images/Fbond.png' },

    // ... and so on for each option
  ];
const CardSection = (props) => {
    
      const [selected, setSelected] = useState(new Array(options.length).fill(false));

 
      
  const handleSelect = (index) => {
    const newSelection = new Array(options.length).fill(false);
    newSelection[index] = true;
    setSelected(newSelection);
  };
  


  const handleButtonClick = () => {
    const selectedIndex = selected.findIndex((s) => s);
    const selectedOption = selectedIndex !== -1 ? options[selectedIndex].name : null;
    props.onStartQuote(selectedOption);
};
  return (

<div className='w-full md:w-full lg:w-[50%] mx-auto mt-10'>
<div className="grid grid-cols-3 gap-4 p-4">
{options.map((option, index) => (
  <div
    key={option.name}
    className={`p-4 border rounded-lg flex flex-col items-center cursor-pointer ${
      selected[index] ? 'bg-blue-500 text-white' : 'bg-gray-200'
    }`}
    onClick={() => handleSelect(index)}
  >
    <div className='flex justify-end w-full'>
    <input
      type="checkbox"
      checked={selected[index]}
      onChange={() => {}}
      className="mb-2 h-5 w-4"
    />
    </div>
    
    <img src={option.imagePath} alt={option.name} className="mb-2 h-12 w-12" />
    <span className='text-center'>{option.name}</span>
  </div>
))}
</div>

<div className='w-full flex items-center justify-center mt-5'>
<button className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-full" onClick={handleButtonClick}>
      Start My Quote
    </button>
</div>
<hr className='mt-[10%] border-t-1 border-gray-300'/>
</div>


  )
}

export default CardSection
